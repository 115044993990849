import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>SickFly</h1>
        <iframe width="970" height="560" src="https://v6p9d9t4.ssl.hwcdn.net/html/1220073/index.html" frameborder="0"></iframe>
        
      </header>
    </div>
  );
}

export default App;
